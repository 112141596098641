/** @jsx jsx */
import { jsx, useColorMode, Grid } from "theme-ui"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


const Hero = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  return (
    <Grid columns={[1, 2]}>
      <div>
        <h3>Contact</h3>
        <div>Twitter: <Link
          sx={(t) => ({ ...t.styles?.a, variant: `links.secondary`, marginY: 2 })}
          to="https://twitter.com/baseballbrad3">
            https://twitter.com/baseballbrad3
          </Link>
        </div>
        <div>Discord: baseballbrad3#9360</div>
        <div>Email:&nbsp;
          { isDark
            ? <StaticImage src="../../../static/e-pic-dark2.png" alt="Email" placeholder="none" loading="eager" height={30} imgStyle={{ transition: `none` }} />
            : <StaticImage src="../../../static/e-pic-light2.png" alt="Email" placeholder="none" loading="eager" height={30} imgStyle={{ transition: `none` }} />
          }
        </div>
      </div>
      <StaticImage src="../../../static/self.jpg" alt="Brad Nadler" placeholder="blurred" loading="eager" imgStyle={{ transitionDuration: `0.075s` }} formats={["auto", "webp", "avif"]} />
    </Grid>
  )
}

export default Hero